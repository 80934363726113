/* eslint-disable react/prop-types */
import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchPage from "./SearchPage/SearchPage"

import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import "assets/scss/material-kit-react.scss?v=1.8.0"

const Search = ({ location, data }) => {
  var leaflets = []
  var searchType = ""
  var searchTerm = ""

  var segments = location.href && location.href.split("/");
  searchType = segments && segments[4]
  searchTerm = segments && decodeURI(segments[5])

  var dataLeaflets = data.allContentfulLeaflet.edges

  if (searchType === "brand") {
    dataLeaflets && dataLeaflets.forEach(leaflet => {
        if (leaflet.node.isEnabled && leaflet.node.brand && searchTerm === leaflet.node.brand.companyName.companyName) {
          leaflets.push(leaflet.node)
        }
      })
  } else if (searchType === "featured") {
    dataLeaflets && dataLeaflets.forEach(leaflet => {
        if (leaflet.node.isEnabled && leaflet.node.feature && searchTerm === leaflet.node.feature[0].title) {
          leaflets.push(leaflet.node)
        }
      })
  } else if (searchType === "category") {
    dataLeaflets && dataLeaflets.forEach(leaflet => {
        if (leaflet.node.isEnabled && leaflet.node.category && leaflet.node.category.find(item => item.name === searchTerm)) {
          leaflets.push(leaflet.node)
        }
      })
  } else if (searchType === "region") {
    dataLeaflets && dataLeaflets.forEach(leaflet => {
        if (leaflet.node.isEnabled && leaflet.node.locations && leaflet.node.locations.find(item => item.locationName === searchTerm)) {
          leaflets.push(leaflet.node)
        }
      })
  } else {
    dataLeaflets && dataLeaflets.forEach(leaflet => {
        searchType = "Catalogue Search"
        if (leaflet.node.isEnabled) {
          leaflets.push(leaflet.node)
        }
      })
  }

  //check if this is the location feature
  //add all leaflets and send isLocationFeature in props
  var nearbyFeatureId = "3c1acd2c-fd62-55d6-ba2a-ba7a384a52a2"
  var featureId =
    location &&
    location.state &&
    location.state.feature &&
    location.state.feature.id
  var isLocationFeature = featureId == nearbyFeatureId
  if (isLocationFeature) {
    data &&
      data.allContentfulLeaflet &&
      data.allContentfulLeaflet.edges &&
      data.allContentfulLeaflet.edges.forEach(leaflet => {
        searchType = "Specials Near Me"
        if (leaflet.node.isEnabled == true) {
          leaflets.push(leaflet.node)
        }
      })
  }

  const bannerImage = getSrc(data.allContentfulSiteInfo.nodes[0].detailImage)

  console.log(bannerImage)

  return (
    <Layout>
      <SEO
        title={searchType}
        description={
          "Search online catalogues, deals, promotions and specials - get the best deals - save" +
          searchType
        }
      />
      <SearchPage
        bannerImage={bannerImage}
        leaflets={leaflets}
        name={searchType}
        isLocationFeature={isLocationFeature}
        keywords={location && location.state && location.state.keywords}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulSiteInfo {
      nodes {
        detailImage {
          file {
            url
          }
        }
      }
    },
    allContentfulLeaflet(sort: { fields: validTo, order: DESC }) {
      pageInfo {
        itemCount
      }
      edges {
        node {
          title
          validFrom
          validTo
          tags
          pages {
            description
            file {
              url
            }
            id
          }
          id
          description {
            childMarkdownRemark {
              html
            }
          }
          locations {
            locationName
            kilometerRange
            location {
              lat
              lon
            }
          }
          brand {
            companyName {
              companyName
            }
          }
          feature {
            title
          }
          category {
            name
          }
          isEnabled
        }
      }
      distinct(field: title)
    }
  }
`

export default Search
